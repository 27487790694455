@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

* {
  scroll-behavior: smooth;
}

body {
  @apply bg-troo-light
}

#root{
  @apply overflow-x-hidden
}

h1, h2, h3, h4, h5, h6, button {
  font-family: 'Jost', sans-serif;
  @apply font-bold
}

.font-jost {
  font-family: 'Jost', sans-serif;
}

@layer base {
  .font-outline-2 {
    -webkit-text-stroke: 1px #18B36A;
  }

  .font-outline-4 {
    -webkit-text-stroke: 4px #18B36A;
  }
}

.btn-primary {
  @apply bg-troo-primary py-4 px-6 text-troo-light font-semibold rounded-lg hover:bg-troo-light hover:text-troo-primary transition-all duration-150 ease-linear hover:shadow-xl
}
.btn-secondary {
  @apply bg-troo-secondary py-4 px-6 text-troo-light font-semibold rounded-lg hover:bg-troo-light hover:text-troo-secondary transition-all duration-150 ease-linear hover:shadow-xl
}

h1 {
  @apply text-3xl md:text-4xl lg:text-7xl leading-[40px] md:leading-[50px] xl:leading-[80px]
}

h2 {
  @apply text-2xl md:text-3xl lg:text-4xl leading-[38px] md:leading-[48px] xl:leading-[44px]
}

h3 {
  @apply text-xl sm:text-2xl md:text-3xl leading-[30px] md:leading-[40px] xl:leading-[50px]
}

h4 {
  @apply text-lg sm:text-xl md:text-2xl leading-[22px] md:leading-[32px] xl:leading-[42px]
}

h5 {
  @apply text-base sm:text-lg md:text-xl leading-[34px]
}

h6 {
  @apply text-sm sm:text-base md:text-lg leading-[30px]
}

.p-1 {
  @apply text-xs sm:text-sm md:text-base
}

.p-2 {
  @apply text-xs sm:text-sm
}

a {
  @apply whitespace-nowrap text-sm md:text-base
}

.activeNav{
  @apply text-troo-primary
}

.activeMobileNav {
  @apply text-troo-primary
}

.main-container {
  @apply max-w-[1920px] mx-auto px-[10px] md:px-[60px] xl:px-[80px] 2xl:px-[100px];
}

.main-container-big {
  @apply max-w-[1920px] mx-auto px-[20px] md:px-[50px] xl:px-[100px] 2xl:px-[280px];
}

.main-container-big-l-0 {
  @apply max-w-[1920px] mx-auto pr-[20px] md:pr-[50px] xl:pr-[100px] 2xl:pr-[280px];
}
.main-container-big-r-0 {
  @apply max-w-[1920px] mx-auto pl-[20px] md:pl-[50px] xl:pl-[100px] 2xl:pl-[280px];
}

.divider-dot{
  @apply w-1.5 h-1.5 bg-troo-tertiary rounded-full
}

.divider-line{
  @apply w-48 h-[1px] bg-troo-light
}

.btn-theme-outlined {
  @apply relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium transition-all bg-transparent text-troo-light border border-troo-light
}

.btn-theme-filled {
  @apply relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium transition-all bg-troo-light text-troo-primary hover:bg-opacity-80
}

.btn-theme-outlined > span {
  @apply w-72 h-72 rounded rotate-[-40deg] bg-troo-light absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9
}
.btn-theme-outlined p {
  @apply relative w-full text-left text-troo-light transition-colors duration-300 ease-in-out font-normal
}

option{
  @apply text-troo-secondary
}

.accordian-input:active .accordian-main {
  background-color: #EBB977 !important;
}

.height-0,
:where(.collapse > input[type="radio"]) {
  min-height: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}